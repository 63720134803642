/**
 * SourceFlow Enumerable id values object
 *
 * For each of the forms and categories used in the project add a key:id pair to this object to avoid writing id's directly in your codebase.
 *
 * Using enumerable values is much easier to understand than adding id's directly in your code and ensures against typo's
 *
 * @example1: Render contact form
 *
 * <SourceFlowForm formId={sourceflowEnums.forms.contactForm} />
 *
 * @example2: Filter jobs by sector internal
 *
 * jobs.filter(i => JSON.stringify(i.categories).includes(sourceflowEnums.categories.sectors.internal))
 */
export const sourceflowEnums = {
  // Forms
  forms: {
    contact_us: "83415506-642c-4c6e-8fda-56dd57894e24",
    schools_refer_a_teacher: "d6bfe377-4efa-4eea-a2e1-b186a31bf556",
    schools_refer_a_school: "04d6b16b-b02f-4ca5-b6d1-ab4105a4887f",
    schools_upload_vacancy: "7ffad9ec-c205-4c9a-9d6c-15dfd8cb981e",
    resources_refer_a_friend: "2be29d3c-ceec-460f-8588-bf8bd5ea7569",
    get_started: "c176150a-d997-405e-bdf6-d7948b3ee9a5",
    blog_newsletter: "0e9989a9-1086-4f74-b2a7-e22fc081bbec",
    permanent_recruitment_submit : "5d7b700c-c977-4619-8b3c-1d48a948734f",
  },

  // Categories
  categories: {
    job_type: {
      id: "3d4b39c8-8922-43e3-925f-820f646ed9d6",
    },
    job_sector: {
      id: "69b6319c-d51b-4fa0-a7c3-96fd7192ca65",
    },
    job_sector_parent: {
      id: "afd3e0bd-a9e1-412d-b7b3-9e91418bce71",
    },
    job_salary_daily: {
      id: "85b34a20-646d-4820-b26a-2cb1357a23bd",
    },
  },

  // SEO
  seo: {
    baseURL: "https://www.twrecruitment.com",
    siteName: "Tradewind",
    titleTemplate: `Permanent and Supply Teaching Jobs | Tradewind Recruitment`,
    genericDescription:
      "Find the perfect teaching job in a local school. Tradewind Recruitment are one of the UK's leading supply teaching agencies, view our teaching jobs and apply!",
    logo: `https://www.twrecruitment.com/images/og-image.jpg`,
    images: [
      {
        url: "https://www.twrecruitment.com/images/og-image.jpg", // replace me
      },
    ],
    google_image: `https://www.twrecruitment.com/images/google-image.jpg`,
    twitter: {
      site: "@TradewindUK",
      cardType: "summary_large_image",
    },
  },
};

export default sourceflowEnums;
